@import './../../../../assets/styles/custom';

.stepper--data-personal-avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.bigsize {
  width: 20rem !important;
  height: 20rem !important;
}

#stepper--personal-data-inputfile {
  display: none;
}

.stepper--personal-data-file {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .8rem;
  border-radius: 6px;
  border: 1px dashed $text-color-blue;
  padding: 1rem;
  cursor: pointer;
}

.stepper--personal-data-name {
  color: $text-color-blue;
  font-weight: 600;
}

.stepper--validation-inputs {
  display: flex;
  justify-content: center;
  gap: 1rem;

  input {
    width: 4rem;
    height: 6rem;
    padding: .5rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 1rem;
    border: 1px solid $text-color-gray-70;
    color: $text-color-gray-70;
    outline-color: $text-color-gray-70;
  }
}

.stepper--validation-notreceivedcode {
  color: $text-color-blue;
  font-weight: 600;
}


@media screen and (min-width: 768px) {
  .container-photo--profile {
    margin-top: -97px;
  }
}
