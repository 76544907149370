@import '../../../assets/styles/custom.scss';

.container-step--number {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid $text-color-blue;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.2rem;
}

.step-active {
  background-color: darken($text-color-blue, 10%);
  border: 0;
  color: #fff;
}

.container-step--title {
  font-size: 1rem;
}

.stepper-item {
  position: relative;
}

.stepper-line {
  position: absolute;
  height: 4px;
  width: 170px;
  background-color: $text-color-blue;
  transition: background-color 3s ease-in-out;
  top: 25%;
  transform: translateY(-50%);
  right: -111px;
}

.stepper-line-gray {
  position: absolute;
  height: 4px;
  width: 170px;
  background-color: $text-color-gray-70;
  top: 25%;
  transform: translateY(-50%);
  right: -111px;
}



@media screen and (max-width: 768px) {
  .stepper.stepper-pills .stepper-nav {
    justify-content: space-between;
    flex-wrap: nowrap !important;
  }

  .stepper-item {
    margin: 0 !important;
  }

  .stepper-line-gray {
    left: 89px !important;
    width: 138px !important;
  }
}

@media screen and (max-width: 576px) {
  .stepper-item > div {
    min-width: 100px !important;
    margin: auto;
  }

  .stepper-line-gray {
    display: none;
  }
}

