.container-card--proccess {
  padding: 1rem;
  height: 12rem;

  .card-body {
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  }

  .btn-show--pdf {
    color: #d9a804 !important;
  }
}

[data-bs-theme=light] .container-card--proccess {
  background-color: #636363;

}

.rpv-core__text-layer {
  display: none;
}
