@import '../../../assets/styles/custom.scss';

.input--file-avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

#input--file-change-file {
  display: none;
}

.input--file-change-file {
  display: flex;
  align-items: center;
  gap: .8rem;
  border-radius: 6px;
  border: 1px dashed $text-color-blue;
  padding: 1rem 2rem;
  cursor: pointer;
  color: #1351b4;
}
