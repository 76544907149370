@import 'custom';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400 !important;
}

::-webkit-scrollbar {
  width: .5rem;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb {
  background: #1351b4;
  border-radius: .5rem;
}

td {
  vertical-align: middle !important;
}

body {
  font-family: $font-family;
  // background-color: #151521 !important;
}

@media screen and (max-width: 958px) {
  .logo-company-page--login {
    img {
      width: 40% !important;
      margin-top: 2rem;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.text-blue {
  color: $text-color-blue;
}

.back-blue {
  background-color: $back-color-blue;
  color: $text-color-white;
}

.back-blue:hover {
  background-color: darken($back-color-blue, 10%);
  color: $text-color-white;
}

.back-gray-02 {
  background-color: $back-color-gray-02;
  color: $text-color-blue;
}

.back-gray-02:hover {
  background-color: darken($back-color-gray-02, 10%);
  color: $text-color-blue;
}

.text-gray-70 {
  color: $text-color-gray-70;
}

.border-color-40 {
  border: 1px solid $border-color-gray-40;

  &:focus {
    border-color: darken($border-color-gray-40, 10%);
  }
}

.border-radius {
  @each $key, $value in $border-radius {
    &-#{$key} {
      border-radius: $value !important;
    }
  }
}

.font-weight {
  @each $key, $value in $font-weight {
    &-#{$key} {
      font-weight: $value !important;
    }
  }
}

.colors-blue {
  @each $key, $value in $colors-blue {
    &-#{$key} {
      background-color: $value !important;
    }

    &-#{$key}:hover {
      background-color: darken($value, 10%) !important;
    }
  }
}

.text-green {
  @each $key, $value in $colors-green {
    &-#{$key} {
      color: $value;
    }
  }
}

.back-green {
  @each $key, $value in $colors-green {
    &-#{$key} {
      background-color: $value !important;
    }
  }
}

.text-blue {
  @each $key, $value in $colors-blue {
    &-#{$key} {
      color: $value;
    }
  }
}

.back-blue {
  @each $key, $value in $colors-blue {
    &-#{$key} {
      background-color: $value !important;
    }
  }
}

.disabled {
  background-color: $text-color-disabled !important;
}


// Novas classes
.bg-blue {
  @each $key, $value in $bg-blue {
    &-#{$key} {
      background-color: $value !important;
    }
  }
}

.bg-white {
  @each $key, $value in $bg-white {
    &-#{$key} {
      background-color: $value;
    }
  }
}

.bg-gray {
  @each $key, $value in $bg-gray {
    &-#{$key} {
      background-color: $value;
    }
  }
}

.bg-yellow {
  @each $key, $value in $bg-yellow {
    &-#{$key} {
      background-color: $value;
    }
  }
}

.text-blue {
  @each $key, $value in $text-blue {
    &-#{$key} {
      color: $value !important;
    }
  }
}

.cursor {
  @each $key, $value in $cursor {
    &-#{$key} {
      cursor: $value !important;
    }
  }
}

.react-multiple-carousel__arrow {
  z-index: 1;
}
