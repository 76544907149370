@import '../../../assets/styles/custom.scss';

.container--session-expired {
  height: 80%;
  margin: auto;
  padding: 0 1rem;
  border-radius: .5rem;

  &:hover {
    filter: brightness(.9);
  }
}

.session-expired-icon {
  width: 10rem;
  height: 10rem;
  margin: auto;
}

[data-bs-theme="light"] .container--session-expired {
  background-color: map-get($bg-yellow, 'light');
}
