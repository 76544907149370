@import './../../../assets/styles/custom';

.container-input--verifycode {
  width: 4rem;
  height: 6rem;
  padding: .5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 1rem;
  border: 1px solid $text-color-gray-70;
  color: $text-color-gray-70;
  outline-color: $text-color-gray-70;
}
