// Variaveis
// A fonte Rawline é do site: https://www.cdnfonts.com/rawline.font
$font-family: 'Rawline', 'Raleway', sans-serif;

// Cores
$text-color-white: #fff;
$text-color-disabled: #ddd;
$text-color-blue: #1351B4;
$back-color-blue: #1351B4;
$back-color-gray-02: #F8F8F8;

$border-color-gray-40: #888;

$text-color-gray-70: #555;

$border-radius: (
  'sm': 0.475rem,
  'md': 1rem,
  'lg': 2rem
) !default;

$font-weight: (
  'light': 300,
  'regular': 400,
  'medium': 500,
  'semi-bold': 600,
  'bold': 700
) !default;

$colors-blue: (
  'light': #175fd4,
  'medium': #1351B4,
  'dark': #0D3A9E
) !default;

$colors-green: (
  'light': #1eb22d,
  'medium': #168821,
  'dark': #106719
) !default;



// Novas variáveis
$bg-blue: (
  'light-white': #d4e5ff,
  'light': #96ccf5,
  'primary': #1351B4
) !default;

$bg-white: (
  'light': #FCFCFC,
  'primary': #FFFFFF,
  'dark': #E3E3E3
) !default;

$bg-gray: (
  'light': #1E1E2D,
  'primary': #151521
) !default;

$bg-yellow: (
  'light': #FFF3EA,
  'primary': #F5C96C
) !default;

$text-blue: (
  'light': #96ccf5,
  'primary': #1351B4
) !default;

$cursor: (
  'pointer': pointer,
  'default': default,
  'not-allowed': not-allowed,
  'wait': wait,
  'text': text,
  'move': move,
  'help': help,
  'progress': progress,
  'crosshair': crosshair,
  'zoom-in': zoom-in,
  'zoom-out': zoom-out,
  'grab': grab,
  'grabbing': grabbing
) !default;

