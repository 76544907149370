.dashboard--page-search-container {
  position: relative;

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #888;
  }
}

.dashboard--page-seach {
  height: 3rem;
  width: 18rem;
  padding: 1.5rem 1rem;
  border-radius: 5px;
  outline: none;
  border: 0;

  &::placeholder {
    font-style: italic;
    color: #000;
  }
}
