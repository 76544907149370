.container-card--notification {
  border-radius: 1rem;
  background-color: #151521;
  padding: 1rem;
  box-shadow: 0px 1px 6px 0px rgba(51, 51, 51, 0.16);
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(.91);
  }
}

[data-bs-theme=light] .container-card--notification {
  background-color: #F8F7F8;
  box-shadow: 2px 2px 2px #ddd;
}
