.container-notification {
  position: relative;
  display: inline-block;
}

.badge-number {
  position: absolute;
  top: -5px;
  right: -6px;
  background-color: red;
  color: white;
  border-radius: 20%;
  padding: 1px 6px 4px 6px;
  font-size: .8rem;
}
