.container--sidebar-lateral {
  background: #1E1E2D;
  padding: 1rem 2rem 1rem .5rem;
  border-radius: .5rem;
  position: fixed;
  z-index: 10;
  transform: translate3d(234px, 0, 0);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: .5rem;

  min-width: 200px;

  // .link-menu {
  //   background-color: #009EF7;
  //   border-radius: .5rem;
  //   padding: .8rem 1.5rem;
  //   min-width: 150px;
  // }
}

[data-bs-theme=light] .app-sidebar .menu .menu-item .menu-link .menu-title {
  color: #fff;
  font-weight: bold !important;
}

[data-bs-theme=light] .app-sidebar .menu .menu-item .menu-link .menu-arrow:after {
  background-color: #DDAA01;
}

[data-bs-theme=light] .menu-sub-lg-dropdown {
  background-color: #E6E6E6;
}

[data-bs-theme=light] .app-sidebar .menu .menu-item .menu-link .menu-icon, [data-kt-app-theme=light] .app-sidebar .menu .menu-item .menu-link .menu-icon .svg-icon, [data-kt-app-theme=light] .app-sidebar .menu .menu-item .menu-link .menu-icon i {
  color: #fff;
}
